import React, { Component, useState, useEffect } from 'react'
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import styled from 'styled-components';
import CheckMark from '../../assets/checkmark4.svg';
import Trash from '../../assets/trash.svg';
import config from '../../aws-exports';
Amplify.configure(config);

export default function Notes(props) {
    const {consoleLog} = require('../commonFunctions.js');
    consoleLog('subline item notes: ',props);
    const [note, setNote] = useState(props.note);
    const [isFocused, setIsFocused] = useState(false);

    function handleInputChange(e) {
        consoleLog("handleInputChange e", e.target.value);
        setNote(e.target.value);
    };

     // handle click event of the Add button
    function handleCheck() {
        
    }
    
    // handle click event of the Remove button
    function handleChange() {
        consoleLog('handleNoteChange count: ', props.count)
        props.onChange(props.count, props.line_item_id);
    }

    function handleInputFocus(e) {
        setIsFocused(true);
    }

    function handleNoteBlur(e) {
        consoleLog("handleNoteBlur Blurred !!!");
        const { name, value } = e.target;
        props.onAddNote(props.count, value);
    }

    return (
        <React.Fragment>
             <Box>
                <input
                    name="note"
                    placeholder="Enter Notes"
                    className="ip my-2 ellipsis input-border"
                    style={{position:'relative'}}
                    value={note}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    onBlur={handleNoteBlur}
                    disabled={props.sublineItemRequst}
                />
                <div className="btn-box text-center right-btn">
                    {isFocused && (
                        <button className="mr10" onClick={handleCheck}>
                            <span><img src={CheckMark} alt=""/></span>
                        </button>
                    )}
                    <button className="mr10" onClick={handleChange}>
                        <span> <img src={Trash} alt=""/> </span>
                    </button>
                </div>
            </Box>
        </React.Fragment>
    )
}

const Box = styled.div`
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    padding: 0.2em 2em;
    border-top: 1px solid #F7F9FA;
    border-bottom: 1px solid #F7F9FA;

    .ip{
        height:40px;
        width:100%;
        outline:none;
        padding:10px;
        border-radius:4px;
        border:none !important;
        color:#212E33;
        font-style: normal;
        font-size:14px;
    }


    .mr10{
        background:transparent;
        border:none;
        outline:none;
    }  
    
    @media(max-width:767px){
        padding: 0;
        justify-content: start;
    }
`