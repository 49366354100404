import React, { Component, useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import { FaPlus, FaCheck } from "react-icons/fa";
import { FaInfoCircle, FaCircleNotch } from "react-icons/fa";
import config from "../../aws-exports";
import Trashh from "../../assets/trash.svg";
import TransparentTrash from "../../assets/trash_transparent.svg";
import Plus from "../../assets/plus.svg";
import { useSelector, useDispatch } from "react-redux";
import {
  removeCategory, updateFinancingOptions
} from "../../graphql/queries";
import Cancel from "../../assets/cancel_line.svg";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import  secureLocalStorage  from  "react-secure-storage";

Amplify.configure(config);

export default function AddFinancingOptions(props) {
  const {consoleLog} = require('../commonFunctions.js');
  const [financeOpChanged , setFinanceOpChanged] = useState('');
  const handlePercetageValue = (val, index) => {
    const updatedOptions = props.financingOptions.map((option, i) => {
      if (i === index) {
        return { ...option, percentage: val };
      }
      return option;
    });
    props.setFinancingOptions([ ...updatedOptions]);
    setFinanceOpChanged(index);
  };

  const handleMonthValue = (val, index) => {
    const updatedOptions = props.financingOptions.map((option, i) => {
      if (i === index) {
        return { ...option, months: val };
      }
      return option;
    });
    props.setFinancingOptions([...updatedOptions]);
    setFinanceOpChanged(index);
  };
  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      if(financeOpChanged != ''){
        console.log("======>>>", props.financingOptions[financeOpChanged]);
        props.updateFinancingOptionsFunc(props.financingOptions[financeOpChanged], props.financingOptions, financeOpChanged);
      }
      setFinanceOpChanged('');
    }, 1000); // 1-second delay

    return () => clearTimeout(delayDebounce);
  }, [financeOpChanged]);

  const handleIsActive = (val, index) => {
    let activeObject
    const updatedOptions = props.financingOptions.map((option, i) => {
      if (i === index) {
        if(val == 'N'){
          val = 'Y';
        }else{
          val = 'N';
        }
        activeObject={ ...option, is_active: val }
        return { ...option, is_active: val };
      }
  
      return option;
    });
    props.setFinancingOptions([ ...updatedOptions]);
    props.updateFinancingOptionsFunc(activeObject,[ ...updatedOptions],index);
  };

  const handleDefault = (def,index) => {
    let defaultObject
   const updatedOptions = props.financingOptions.map((option, i) => {
      if (i === index) {
        if(def == 'N'){
          def = 'Y';
        }else{
          def = 'N';
        }
        defaultObject={ ...option, is_default: def }
        return { ...option, is_default: def };
      }
  
      return option;
    });
    props.setFinancingOptions([ ...updatedOptions]);
    props.updateFinancingOptionsFunc(defaultObject,[ ...updatedOptions],index);
  };

  return (
    <AddStruct>
      
      <Row className="mb-2">
        <div className="col-xs-9 text-left d-flex align-items-center justify-content-between w-100">
          <h4 className="tax_text">
            {" "}
            Add Financing Options{" "}
            <Tooltip
            overlay={
              <div
                style={{
                  width: "250px",
                  padding: "10px",
                  background: "#fff",
                  color: "#000",
                  fontSize: "14px",
                  fontFamily: "Roboto",
                  lineHeight: "1.5", // Ensures good readability
                }}
              >
                <b>Financing Options</b><br />
                Input customer financing options or get pre-approved options through <a href="https://www.skeps.com/" target="_blank">Skeps.com</a>.
                Scope Technologies disclaims any liability related to your use of third-party services. 
                <a href="https://roofscope.com/terms" target="_blank">Terms of Use</a>
              </div>
            }
            placement="left"
          >
            <small style={{ marginLeft: "5px", marginTop: "5px" }}>
              <FaInfoCircle size={15} style={{ color: "#F7941C" }} />
            </small>
          </Tooltip>
          </h4>
          <button
            className="border-0 px-0 outline-none bg-trans"
            onClick={() => props.hideMarkupOrTaxes('financing')}
          >
            <img
              src={Cancel}
              alt=""
              style={{ height: "auto", cursor: "pointer", width: "26px" }}
            />
          </button>
        </div>
      </Row>

        <div className="d-flex  justify-content-center w-100 flex-column">
          <p className="m-0 mt-2" style={{fontFamily: "Roboto", fontSize: "14px", color: "rgb(33, 46, 51)", fontWeight: "500"}}>Add Finance Rate % and Duration (Include up to 3 options)</p>
          {props.financingOptions.map((finance,index)=>{
            return <div className="d-flex align-items-center justify-direction w-100 flex-column flex-md-row" style={{columnGap: "25px"}}>
            <div className="d-flex align-items-center position-relative w-100 mt-3">
              <input
                type="text"
                className="ip2x mr-1"
                style={{ position: "relative" }}
                
                value={finance.percentage=="0.00"?"":finance.percentage}
                onChange={(e) => {
                  const regix = new RegExp("^[0-9]*[/.]?([0-9]{1,3})?$");
                  if (regix.test(e.target.value)) {
                    if(new Number(e.target.value)<=100){
                      handlePercetageValue(e.target.value, index)
                    }
                  }
                  }}
                onBlur={()=>{props.updateFinancingOptionsFunc(finance,props.financingOptions,index)}}
                placeholder={finance.percentage=="0.00"?"0.00":"Finance Rate %"}
              />
              <span
                class="percent-sign"
                style={{
                  position: "absolute",
                  right: "16%",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                %
              </span>
            </div>
            <div className="d-flex align-items-center position-relative w-100 mt-3">
              <input
                type="text"
                className="ip2x mr-1"
                style={{ position: "relative" }}
                value={finance.months=="0.00"?"":finance.months}
                onChange={(e) => {
                  const regix = new RegExp("^[0-9]?([0-9]*)?$");
                  if (regix.test(e.target.value)) {
                    handleMonthValue(e.target.value, index)
                  }}}
                onBlur={()=>{props.updateFinancingOptionsFunc(finance,props.financingOptions,index)}}
                placeholder={finance.months=="0.00"?"0.00":"Duration (Months)"}
              />
              <span
                class="percent-sign"
                style={{
                  position: "absolute",
                  right: "16%",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                Months
              </span>
            </div>
            <div className="w-100 d-flex align-items-center justify-content-end ml-5 test-center mt-3">
              <div className="w-100">
                <input
                className="mt-0"
                  type="checkbox"
                  checked={finance?.is_default=='Y'}
                  onChange={() =>handleDefault(finance?.is_default,index,finance)}
                />
                <small
                  style={{
                    color: "#737F82",
                    marginTop:"2px",
                    marginLeft: "5%",
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    letterSpacing: "0",
                    lineHeight: "16px",
                  }}
                >
                  Save as default
                </small>
              </div>
              {finance.is_active == 'Y' ? 
                (
                  <>
                    <div className="ex-tax mr-4" style={{background:'#05d6a0'}}>
                      <FaCheck style={{width:"15px"}}/>
                    </div>
                    <div className="ex-tax mr-4" onClick={()=>handleIsActive(finance.is_active, index)}>
                      <img
                        src={TransparentTrash}
                        style={{ cursor: "pointer" }}
                        alt=""
                      />
                    </div>
                  </>
                ) : (
                  <div className="ex-tax mr-4" onClick={()=>handleIsActive(finance.is_active, index)}>
                    <FaPlus style={{width:"10px", color: "#414141", cursor:"pointer"}}/>
                  </div>
                )
              }
            </div>
          </div>
          })}
      
        </div>
   
    </AddStruct>
  );
}

const AddStruct = styled.div`
  margin-top: 1%;
  min-height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  padding: 20px 45px;
  font-family: Roboto;

  border-radius: 6px;
  background-color: #eef2f4;
  @media (max-width: 600px) {
    padding: 10px 20px;
  }

  .ip2x {
    height: 40px;
    width: 90%;
    outline: none;
    padding: 10px;
    font-style: normal;
    border-radius: 4px;
    border: 1px solid #b4bebf;
    color: #212e33;
    @media (max-width: 600px) {
      width: 100%;
    }
  }

  .close{
        right:20px !important;
        top:1rem !important;
    }

    .toast-header{
        padding:0 20px;
    }
    .toast-body{
        padding:0 20px;
    }


  .hover-opt {
    text-decoration: none;
    color: #212e33;
    &:hover {
      color: #f7941c;
    }
  }
  .width {
    width: 98.5%;
  }
  .search-submit {
    height: 40px !important;
    width: 40px !important;
  }

  .content-section {
    @media(max-width: 1024px){
      width: 100% !important;
      margin-top: 5px !important;
    }
  }

  .content-block {
    @media(max-width: 1024px){
      justify-content: start !important;
      margin-left: 3% !important;
      margin-top: 5px !important;
    }
  }
  .custom-link-input {
    height: 40px;
    width: 100%;
    border: 1px solid #b4bebf;
    outline: none;
    border-radius: 4px;
    color: #212e33;
    font-style: normal;
    padding: 10px;
  }

  .width{
    @media(max-width: 1024px) {
      width: 60% !important;
    } 

    @media(max-width: 767px) {
      width: 40% !important;
    } 
  }


  .justify-direction {
    @media(max-width: 767px) {
      justify-content: space-between !important;
    }
  }
  .save-input {
    @media(max-width: 767px){
      margin-left: 0 !important;
      width: auto !important;

      small {
        margin-left: 5px !important;
        font-size: 13px !important;
      }
     }
  }
  .ex-tax {
    height: 40px;
    width: 50px;
    font-weight: 600;
    text-transform: uppercase;
    color: white;
    background: #d6e1e5;
    border: none;
    outline: none;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
  }

  .trash-button {
    width: 40px;

    @media(max-width: 767px) {
      margin-right: 0 !important;
      height: 40px;
    }
  }
  .custombtnn {
    font-weight: 500 !important;
    font-size: 14px;
    text-decoration: initial;
  }

  .w-15 {
    width: 15%;
  }
  .w-25 {
    width: 25%;
  }
  .top {
    text-transform: uppercase;
    font-size: 1.1em;
    font-weight: 600;
    padding: 0.5em;
    vertical-align: top;
  }
  .tax_text {
    color: #212e33;
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
  }
  .popup-tooltip {
    font-size: 0.8em;
    text-transform: none;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.2em;
  }
  .custom_span {
    color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -25px;
    z-index: 9;
    border-radius: 5px;
  }
  .sec_row {
    input[type="checkbox"] {
      border: 1px solid #000;
      border-radius: 50%;
    }
  }

  .catBox_child {
    width: 98.5%;
    border: 1px solid #b4bebf;
    border-radius: 4px;
    padding: 5px 0px 0px !important;
    outline: none;
    button {
      border-bottom: 1px solid #c2c2c2;

     
    }
  }

  .bg-white {
    padding: 10px;
  }
  .buttons {
    padding: 0;
  }

  .row {
    position: relative;
    display: block;
    text-align: center;
    box-sizing: border-box;
    width: 100%;
    float: none;
  }
  .left {
    float: left;
  }
  .add-structure {
    background: #414141;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
    padding: 0.6em 1.8em 0.6em 1.2em;
  }
  .show-item-adder {
    display: block;
    cursor: pointer;
    padding: 0.5em;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  a {
    color: #414141;
    text-decoration: none;
    outline: 0;
  }
  .color-white {
    color: white;
  }
  .add-structure .text {
    color: white;
    font-size: 1em;
  }
  .buttons .show-item-adder .text {
    font-size: 1.2em;
    text-decoration: underline;
    padding-left: 0.5em;
  }
  .buttons .show-item-adder {
    display: block;
    cursor: pointer;
    padding: 0.5em;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .item-adder.hide {
    min-height: 0;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    border: none !important;
    display: none;
  }
  .item-adder {
    overflow: hidden;
    -webkit-animation: height-show 0.5s forwards;
    animation: height-show 0.5s forwards;
    
    position: relative;
  }
  .item-adder .close {
    position: absolute;
    top: 1em;
    right: 2em;
    cursor: pointer;
    z-index: 5;
  }
  .item-adder h2 {
    font-size: 1.1em;
    text-transform: uppercase;
    margin: 0;
  }
  .col {
    display: inline-block;
    box-sizing: border-box;
    vertical-align: middle;
    text-align: center;
    position: relative;
    min-height: 1px;
    line-height: 1em;
    width: 100%;
    float: none;
  }
  .col:last-child {
    margin-right: 0 !important;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }
  h2 {
    font: 1.5em "Roboto";
  }
  div,
  span,
  form {
    border: 0;
    font-size: 100%;
    font: inherit;
    line-height: 1.4em;
    vertical-align: baseline;
    margin: 0;
    padding: 0;
  }
  .item-adder .create-form {
    margin-top: 1em;
  }

  button,
  .button,
  .row .button {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: inline-block;
    text-decoration: none;
    outline: 0;
    cursor: pointer;
    font-size: 1em;
    line-height: 1.3em;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    background: #f7941c;
    border: 1px solid #08527d;
    border-radius: 3px;
    padding: 0.8em 1em;
  }
  button,
  .button,
  .row .button {
    font-weight: 600;
    text-transform: uppercase;
    color: white;
    background: #d6e1e5;
    border: none;
    outline: none;
    border-radius: 3px;
    padding: 0.6em 0.8em;
    box-sizing: border-box;
  }
  button.small,
  .button.small,
  .row .button.small {
    font-size: 0.8em;
    padding: 0.5em 1.2em;
  }
  .item-adder .create-form button {
    padding: 0.7em 1em;
  }
`;
