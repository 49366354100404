import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Logo from "../../assets/prodocs-logo.png";
import Modal from 'react-bootstrap/Modal';
import CheckBox from '../../assets/checkmark3.svg';
import Loader from "./../../assets/loading.gif";
import { Navbar, Nav, NavDropdown, MenuItem } from "react-bootstrap";
import {
  FaRegEdit,
  FaThList,
  FaCog,
  FaMoneyCheck,
  FaTrashAlt,
  FaPlus,
  FaRegFile,
  FaQuestionCircle,
  FaTruck,
  FaCalculator,
  FaWindowClose,
  FaRegCopy,
  FaPencilAlt,
  FaCheck
} from "react-icons/fa";
import styled from "styled-components";
import {
  Accordion,
  Card,
  Button,
  Table,
  Row,
  Col,
  Dropdown,
  DropdownButton,
  ButtonGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "react-bootstrap";
import { Link } from "gatsby";
import { navigate } from "gatsby";
import { useSelector, useDispatch } from "react-redux";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
import config from "../../aws-exports";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import ExitModal from '../../Components/Modals/ExitModal';
import SaveAsTempModalx from '../../Components/Modals/SaveAsTempModalx';
import SaveAsTempModal from '../../Components/Modals/SaveAsTempModal';
import SaveAsEstimateModal from '../Modals/SaveAsEstimateModal';
import SaveAsWorkOrderModal from '../Modals/SaveAsWorkOrderModal';
import { getDefaultTemplatesRedis, saveTemplateJson, saveTemplateAs, getWOTemplates, getTemplatesByTeamIDElasticsearch, getTemplatesByTeamID, elasticSearchDeleteDocOrTemp, deleteDocumentWorkOrder, getEstimateTemplates, saveEstimateDoc, saveWorkOrderDoc } from "../../graphql/queries";
import Cancel from "../../assets/cross.svg";
import EST from "../../assets/est.svg";
import WO from "../../assets/wo.svg";
import { ConsoleLogger } from "@aws-amplify/core";
import GeneralPopup from '../Modals/GeneralPopup'
import  secureLocalStorage  from  "react-secure-storage";

export default function DocNavbar(props) {

  const dispatch = useDispatch();
  const {consoleLog} = require('../commonFunctions.js');
  let loadMoreClicked = false 
  consoleLog("DocNavbar props: ", props);
  const [templateInfo, setDocType] = React.useState(
    useSelector((state) => state.templateInfo)
  );
  const [stData, setStData] = React.useState(
    useSelector((state) => state.templateStructure)
  );
  const [activeNavBar, setActiveNavBar] = React.useState("line_items");
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShow2, setModalShow2] = React.useState(false);
  const [modalShow4, setModalShow4] = React.useState(false);
  const [save, setSave] =  React.useState('');
  const [showDeleteModal, setShowDeleteModal] =  React.useState(false);
  const [showApplyTemplate, setShowApplyTemplate] =  React.useState(false);
  const [applyTemplateData, setApplyTemplateData] =  React.useState(0);
  const [open , setOpen]= useState(false)
  const [showSlider , setShowSlider] = useState(false)
  const [editTitleBox , setEditTitleBox] = useState(false)

  consoleLog("DocNavbar templateInfo: ", templateInfo);
  consoleLog("DocNavbar stData: ", stData);

  const [myTemplates, setMyTemplates] = React.useState([]);
  const [myTemplatesCount, setMyTemplatesCount] = React.useState(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(process.env.GATSBY_DEFAULT_LIST_SIZE ? process.env.GATSBY_DEFAULT_LIST_SIZE : 10);
  const [starterTemplates, setStarterTemplates] = React.useState([]);

  const updatedStructure = useSelector((state) => state.templateStructure);

  consoleLog('DocNavccc',JSON.parse(secureLocalStorage.getItem("client_data")).client_id);

  async function fetchTemplates(){

    const rd_starter_temp = await API.graphql({
      query: getDefaultTemplatesRedis,
      variables: {
          id: 1,
      }
    });
    let rd_temp = JSON.parse(rd_starter_temp.data.getDefaultTemplatesRedis);
    consoleLog("fetchTemplates rd_starter_temp: ", rd_temp);

    if(rd_temp.body != null){
      let rd_tempx = JSON.parse(rd_temp.body);
      consoleLog("fetchTemplates rd_starter_tempx: ", rd_tempx);
      let frd_temps = rd_tempx.filter((x) => x.doc_type_id == props.doc_type_id);
      setStarterTemplates(frd_temps);
    }
    
    consoleLog("fetchTemplates doc_type_id: ", props.doc_type_id);
    if (props.doc_type_id == 2) {
      if(rd_temp.body == null){
        const starter_temp = await API.graphql({
          query: getWOTemplates,
          variables: {
              user_id: process.env.GATSBY_STARTER_TEMPLATES_USER_ID
          }
        });
        var s_temp = JSON.parse(starter_temp.data.getWOTemplates);
        setStarterTemplates(s_temp);
        consoleLog("fetchTemplates starter_temp: ", s_temp);
      }

      // let userId = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;

      // const my_temp = await API.graphql({
      //   query: getWOTemplates,
      //   variables: {
      //       user_id: userId,
      //   }
      // });
      // var m_temp = JSON.parse(my_temp.data.getWOTemplates);
      // setMyTemplates(m_temp);
      // consoleLog("fetchTemplates my_temp: ", m_temp);
      let team_id = JSON.parse(secureLocalStorage.getItem('client_data')).team_id;
      const team_templates = await API.graphql({
          query: getTemplatesByTeamIDElasticsearch,
          variables: {
              team_id: team_id,
              doc_type_id: 2,
              limit: itemsPerPage,
              offset: 0,
              search: ''
          }
      });
      let my_templates = [];
      let my_templates_rec = 0;

      my_templates = JSON.parse(team_templates.data.getTemplatesByTeamIDElasticsearch).data;
      my_templates_rec = JSON.parse(team_templates.data.getTemplatesByTeamIDElasticsearch).count;

      let m_temp = [];

      if(my_templates.length < 1){
        const team_templatex = await API.graphql({
            query: getTemplatesByTeamID,
            variables: {
                team_id: team_id,
                doc_type_id: props.doc_type_id,
                limit: itemsPerPage,
                offset: 0,
                search: ''
            }
        });
        my_templates = JSON.parse(team_templatex.data.getTemplatesByTeamID).data;
        my_templates_rec = JSON.parse(team_templatex.data.getTemplatesByTeamID).count.count;
        m_temp=my_templates;
        consoleLog('fetchTemplates get from DB...',m_temp);
      }else{
        m_temp = my_templates.map(function(element){
          return element._source;
        });
      }

      consoleLog("fetchTemplates my_templates>",my_templates);
      consoleLog("fetchTemplates my_templates_re>",my_templates_rec);
      setMyTemplates(m_temp);
      setMyTemplatesCount(my_templates_rec);
    }
    if (props.doc_type_id == 1) {
      consoleLog('fetchTemplates', 'Estimates');
      if(rd_temp.body == null){
        const starter_temp = await API.graphql({
          query: getEstimateTemplates,
          variables: {
              user_id: process.env.GATSBY_STARTER_TEMPLATES_USER_ID,
          }
        });
        var s_temp = JSON.parse(starter_temp.data.getEstimateTemplates);
        setStarterTemplates(s_temp);
        consoleLog("fetchTemplates starter_temp: ", s_temp);
      }
      // let userId = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;

      // const my_temp = await API.graphql({
      //   query: getEstimateTemplates,
      //   variables: {
      //       user_id: userId,
      //   }
      // });
      // var m_temp = JSON.parse(my_temp.data.getEstimateTemplates);
      // setMyTemplates(m_temp);
      // consoleLog("fetchTemplates my_temp: ", m_temp);
      let team_id = JSON.parse(secureLocalStorage.getItem('client_data')).team_id;
      const team_templates = await API.graphql({
          query: getTemplatesByTeamIDElasticsearch,
          variables: {
              team_id: team_id,
              doc_type_id: 1,
              limit: itemsPerPage,
              offset: 0,
              search: ''
          }
      });
      let my_templates = [];
      let my_templates_rec = 0;

      my_templates = JSON.parse(team_templates.data.getTemplatesByTeamIDElasticsearch).data;
      my_templates_rec = JSON.parse(team_templates.data.getTemplatesByTeamIDElasticsearch).count;
      let m_temp = [];

      if(my_templates.length < 1){
        const team_templatex = await API.graphql({
            query: getTemplatesByTeamID,
            variables: {
                team_id: team_id,
                doc_type_id: props.doc_type_id,
                limit: itemsPerPage,
                offset: 0,
                search: ''
            }
        });
        my_templates = JSON.parse(team_templatex.data.getTemplatesByTeamID).data;
        my_templates_rec = JSON.parse(team_templatex.data.getTemplatesByTeamID).count.count;
        m_temp=my_templates;
        consoleLog('fetchTemplates get from DB...',m_temp);
      }else{
        m_temp = my_templates.map(function(element){
          return element._source;
        });
      }
      
      consoleLog("fetchTemplates my_templates>",my_templates);
      consoleLog("fetchTemplates my_templates_re>",my_templates_rec);
      setMyTemplates(m_temp);
      setMyTemplatesCount(my_templates_rec);
    }
  }

  async function loadMoreTemplates(){
    setShowSlider(true)
    consoleLog('loadMoreTemplates myTemplates.length>', myTemplates.length);
    consoleLog('loadMoreTemplates itemsPerPage>', itemsPerPage);
    let newLimit = myTemplates.length + +itemsPerPage;
    consoleLog('loadMoreTemplates newLimit>', newLimit);

    let team_id = JSON.parse(secureLocalStorage.getItem('client_data')).team_id;
    const team_templates = await API.graphql({
        query: getTemplatesByTeamIDElasticsearch,
        variables: {
            team_id: team_id,
            doc_type_id: props.doc_type_id,
            limit: newLimit,
            offset: 0,
            search: ''
        }
    });
    let my_templates = [];
    let my_templates_rec = 0;

    my_templates = JSON.parse(team_templates.data.getTemplatesByTeamIDElasticsearch).data;
    my_templates_rec = JSON.parse(team_templates.data.getTemplatesByTeamIDElasticsearch).count;
    let m_temp = [];

    if(my_templates.length < 1){
      const team_templatex = await API.graphql({
          query: getTemplatesByTeamID,
          variables: {
              team_id: team_id,
              doc_type_id: props.doc_type_id,
              limit: itemsPerPage,
              offset: 0,
              search: ''
          }
      });
      my_templates = JSON.parse(team_templatex.data.getTemplatesByTeamID).data;
      my_templates_rec = JSON.parse(team_templatex.data.getTemplatesByTeamID).count.count;
      m_temp=my_templates;
      consoleLog('loadMoreTemplates get from DB...',m_temp);
    }else{
      m_temp = my_templates.map(function(element){
        return element._source;
      });
    }
    
    consoleLog("loadMoreTemplates my_templates>",my_templates);
    consoleLog("loadMoreTemplates my_templates_re>",my_templates_rec);
    setMyTemplates(m_temp);
    // setMyTemplatesCount(my_templates_rec);
  }

  const getDateTime = () => {
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    let date = [year, month, day].join('-');

    let hour = d.getHours();
    let minutes = d.getMinutes();

    if (hour.length < 2)
        hour = '0' + hour;
    if (minutes.length < 2)
        minutes = '0' + minutes;

    let time = [hour, minutes].join(':');

    return date+' '+time+':00'; 
  }

  async function saveDoc() {
    // to save the document
    // consoleLog("template id to save: ", templateInfo.template_id);
    // consoleLog("structure data to save: ", stData);
    // var project_attributes_json_replaced = stData.replace(/"/g, '\\"');

    // consoleLog('updatedStructure', updatedStructure);
    props.setLoading(true);
    let client_id = JSON.parse(secureLocalStorage.getItem("client_data")).client_id;
    let date_time = getDateTime();
    let all_data = { ...updatedStructure , name:templateName};
    consoleLog("updatedStructure all_data: ", all_data);
    let data_to_save = JSON.stringify(all_data);
    consoleLog("updatedStructure data_to_save thiss: ", data_to_save);

    consoleLog("templateInfo.template_id> ", templateInfo.template_id);
    // let all_data = {...stData};
    // consoleLog("all_data: ", all_data);
    // let data_to_save = JSON.stringify(all_data);
    // consoleLog("data_to_save thiss: ", data_to_save);

    // uncomment the following code to save the document

    const save_template_obj = await API.graphql({
        query: saveTemplateJson,
        variables: {
            template_id: templateInfo.template_id,
            data: data_to_save,
            date_updated:date_time,
            update_user_id:client_id,
            es_down_flag:'0'
        }
    })
    props.updateDocOnElasticSearch();
    consoleLog("save_template_obj result: ", save_template_obj);
    setSave(save_template_obj);
    props.setLoading(false);
    setModalShow2(true);
  }

  async function deleteDocWorkOrder(){ //it is being used for both Estimate / Work Order 
    props.setLoading(true);
    consoleLog('deleteDocWorkOrder', props.doc_id);
    const delete_doc = await API.graphql({ // delete from DB
      query: deleteDocumentWorkOrder,
      variables: {
        doc_id: props.doc_id,
      }
    });
    let res = JSON.parse(delete_doc.data.deleteDocumentWorkOrder);
    consoleLog('deleteDocWorkOrder>', res);
    consoleLog('deleteDocWorkOrder>', res.status);
    if(res.status==1){
      const delete_es_doc = await API.graphql({ // delete from elasticsearch
        query: elasticSearchDeleteDocOrTemp,
        variables: {
          id: props.doc_id,
          index: 'prodocs-documents'
        }
      });
      let es_result = JSON.parse(delete_es_doc.data.elasticSearchDeleteDocOrTemp);
        consoleLog('deleteDocWorkOrder>', es_result.status);
      if(es_result.status != 200){
        consoleLog('deleteDocWorkOrder handle_es_down status>', es_result.status);
        let es_down_docs = JSON.parse(secureLocalStorage.getItem("es_down_docs")).body.docs;
        es_down_docs.push({doc_id : props.doc_id, es_down_flag : '3'});
        consoleLog('deleteDocWorkOrder handle_es_down es_down_docs>>>', es_down_docs);
        let temp_docs_temp = JSON.parse(secureLocalStorage.getItem("es_down_docs"));
        consoleLog('deleteDocWorkOrder handle_es_down temp_docs_temp>>>', temp_docs_temp);

        temp_docs_temp.body.docs = es_down_docs;
        consoleLog('deleteDocWorkOrder handle_es_down temp_docs_temp final>>>', temp_docs_temp);
        secureLocalStorage.setItem('es_down_docs', JSON.stringify(temp_docs_temp));
      }
      navigate('/document');

    }else{
      consoleLog('deleteDocWorkOrder>',res);
    }
    props.setLoading(false);
  }
  const [templateName , setTemplateName] = useState('');
  function handleUpdateTemplateName(){
    console.log(templateName,"templateName");
    const update_struct = stData;
    update_struct.name = templateName;
    setStData(update_struct);
    dispatch({ type: "TEMPLATE_STRUCTURE", value: update_struct });
    setEditTitleBox(false);
    saveDoc();
  }
  function onClickLineItems() {
    consoleLog("onClickLineItems", '()');
    props.showLineItem("line_items");
    setActiveNavBar("line_items");
    props.set_show_taxes_and_markup(true);
  }

  function onClickDocDetail() {
    consoleLog("onClickDocDetail", props);
    props.showDocDetail("doc_details");
    setActiveNavBar("doc_details");
  }

  function onClickDocSetting() {
    consoleLog("onClickDocSetting", "()");
    props.showSettingOption("doc_settings");
    setActiveNavBar("doc_settings");
  }

  function saveTemp(){
    consoleLog('saveTemp>',stData);
  }

  const handleExit = () => {
    setModalShow(true);
  }

  const redirectionHandel = () =>{
    setModalShow2(false);
  }

  const saveAsTemplatePopUp = () => {
    consoleLog('saveAsTemplatePopUp', '()');
    props.setModalShow6x(true);
  }

  useEffect(() => {
      setShowSlider(false)
      fetchTemplates();
  }, []);

  return (
    <ContentContainer>
      {/* Delete Document Popup */}
      {
        showDeleteModal &&
      <GeneralPopup 
        titleText="Are You Sure?"
        bodyText={<>Are you sure you want to <b>delete</b> this document?</>}
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        confirm={deleteDocWorkOrder}
      />
      }
      {/* Apply Template Popup */}
      { showApplyTemplate &&
        <GeneralPopup 
          titleText="Are You Sure?"
          bodyText={<>Are you sure you want to apply <b>{applyTemplateData.name}</b> to this document?</>}
          showModal={showApplyTemplate}
          setShowModal={setShowApplyTemplate}
          confirm={()=>{props.applyDocumentTemplate(applyTemplateData.template_id)}}
        />
      }
      <Row>
        <div className="first_row">

            <div className="left-side">
              <Link to="/document">
                <img
                  style={{ width: "120px" }}
                  className="ml-3"
                  src={Logo}
                  alt="Logo"
                />
              </Link>

              {/* { (templateInfo.doc_type_id == 2) &&
                                    <span className="temp_icon mr-3" style={{background: "#9ac8f1"}}> <FaTruck/></span>
                                } */}
              {/* <p className="title m-0">{templateInfo.template_name}</p> */}
            </div>
            <div className="right-side">
              {/* <Button className="saveTempBtn" onClick={ () => saveDoc() }>
                                    Generate Estimate
                                </Button> */}
              {/* <Link
                to="/"
                className="title m-0"
                style={{
                  fontFamily: "Rajdhani",
                  textDecoration: "none",
                  color: "#212E33",
                  fontWeight: "600",
                  fontSize: "16px",
                }}
              >
                <span className="pr-2">
                  {" "}
                  <img src={Cancel} alt="" />{" "}
                </span>
                EXIT{" "}
              </Link> */}
              <a
                onClick={handleExit}
                className="title m-0 right-bar-link"
                style={{fontFamily:'Rajdhani', fontWeight: '600', textDecoration:'none', color:'#212E33', fontSize:'16px'}} >
                <span className="pr-2"> <img style={{width:"26px" }} src={Cancel} alt=""/> </span>
                EXIT
              </a>
            </div>

        </div>
        <div className="second_row">
          <div className="wrap">
            <Row
              className="m-0 sec_row_info w-100 px-md-0 second-row-block"
              style={{ minHeight: "70px" }}
            >
              <div className="col left-side2 px-md-0">
                <span className="temp_icon mr-3">

                  <img src={props.doc_type_id == 1 ? EST : WO} alt="" />
                </span>
                <p className="mb-0 title-ti">{stData.name}</p>
                {editTitleBox == true ? 
                <div className="doc_lbl w-50">
                  <input type="text" name="doc_label" value={templateName} onInput={(e)=>{setTemplateName(e.target.value)}} className="setting-input"/>
                  <span className="cursor-pointer ml-3 mt-2" onClick={()=>{handleUpdateTemplateName()}}><FaCheck /></span>
                </div>
                :<span className="cursor-pointer" onClick={() => {setEditTitleBox(true);setTemplateName(stData.name); } }> <FaPencilAlt /></span>}
              </div>
              <div className="col right-side right-side2 px-md-0">
              {typeof props.doc_id == 'undefined' &&
                <a className="saveTempBtn" onClick={() => saveDoc()}>
                   Generate Work Order
                </a>
              }
              {props.doc_type_id == 2 &&
                <a className="saveTempBtn" onClick={() => props.saveDocWorkOrder()}>
                   Generate Work Order
                </a>
              }
              {props.doc_type_id == 1 &&
                <a className="generate_estimates" onClick={() => props.saveDocEstimate()}>
                   Generate Estimate
                </a>
              }
              </div>
            </Row>
          </div>
                        <div>
                       { modalShow &&
                            <ExitModal
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                                redirect={'/document'}
                            />
                       }
                       {
                        props.modalShow3 &&
                         <SaveAsTempModal
                         show={props.modalShow3}
                         setModalShow={props.setModalShow3}
                         // tempId={props.tempId}
                         doc_type_id={props.doc_type_id}
                         />
                        }
                            <SaveAsEstimateModal
                              show={modalShow4}
                              setModalShow={setModalShow4}
                            />
                            {
                              props.modalShow6x &&
                            <SaveAsTempModalx
                            show={props.modalShow6x}
                            setModalShow={props.setModalShow6x}
                              saveAsTemplate={props.saveAsTemplate}
                              satName={props.satName}
                              setSatName={props.setSatName}
                              satDesc={props.satDesc}
                              setSatDesc={props.setSatDesc}
                              satError={props.satError}
                              setSatError={props.setSatError}
                              myTemplates={myTemplates}
                              setExitingTemplateID={props.setExitingTemplateID}
                              exitingTemplateID={props.exitingTemplateID}
                              />
                            }
                            <SaveAsWorkOrderModal
                              show={props.modalShow5}
                              setModalShow={props.setModalShow5}
                            />
                        </div>
                        {
                          save && modalShow2?
                        <Modal
                        {...props}
                        show={modalShow2}
                        onHide={() => props.setModalShow2(true)}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        style={{opacity:'1'}}
                      >
                        <Modal.Header>

                          <Modal.Title id="contained-modal-title-vcenter">
                              <div className="text-center my-3">
                                  <img src={CheckBox} style={{width:'60px', height:'auto'}} alt=""/>
                              </div>
                              <p className="modal-title-x mb-0 mt-2">Your Template Has Been Saved</p>
                          </Modal.Title>
                        </Modal.Header>

                        <Modal.Footer>
                          <Button className="modal-btn-x" onClick={redirectionHandel}>Close</Button>
                        </Modal.Footer>
                      </Modal>
                       :
                       <div>

                       </div>
                   }
          <div className="wrap custom-padding-dovNav">
            <Row className="m-0">
              <div className="custom-width">
                <div className="left-side-icons mr-7">
                  <p
                    className={`title s_nav_item ${
                      activeNavBar == "line_items" ? "s_nav_item_active" : ""
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={onClickLineItems}
                  >
                    {" "}
                    Line Items
                  </p>
                  {templateInfo.doc_type_id == 1 && (
                    <a
                      className="title ml-5 s_nav_item"
                      style={{ cursor: "pointer" }}
                      onClick={onClickLineItems}
                      href="#taxes"
                    >
                      {" "}
                      Taxes & Markups
                    </a>
                  )}
                  {props.doc_id > 0 &&
                    <p
                      className={`title ml-5 s_nav_item ${
                        activeNavBar == "doc_details" ? "s_nav_item_active" : ""
                      }`}
                      style={{ cursor: "pointer" }}
                      onClick={onClickDocDetail}
                    >
                      {" "}
                      Details
                    </p>
                  }
                  <p
                    className={`title ml-5 s_nav_item ${
                      activeNavBar == "doc_settings" ? "s_nav_item_active" : ""
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={onClickDocSetting}
                  >
                    {" "}
                    {typeof props.doc_id != 'undefined' ? 'Doc Settings' : 'Template Settings'}
                  </p>

                  <Dropdown show={open} onToggle={() => {
                    !loadMoreClicked && setOpen(!open)
                  }} className="d-inline last-child ml-5" direction="right" autoClose="outside">
                    <Dropdown.Toggle
                      id="dropdown-autoclose-outside"
                      className="drop-down-btn s_nav_item"
                      style={{
                        color: "#212E33",
                        fontWeight: "bold",
                        textTransform: "initial",
                        fontSize: "16px",
                        fontFamily: "Rajdhani",
                        margin: 0,
                        padding: 0
                      }}
                    >
                      Templates
                    </Dropdown.Toggle>
                    <Dropdown.Menu >
                      <DropdownSubmenu
                        className={`custom-nav-br ${showSlider ? 'slider' : ''}`}                        // href="#action/3.7"
                        style={{
                          color: "#fff !important",
                          fontWeight: "bold",
                          fontSize: "14px",
                          fontFamily: "Rajdhani",
                        }}
                        title="My Templates"
                      >
                       
                        { myTemplates.length ?
                        ( myTemplates.map((temp, i) => (
                          <NavDropdown.Item
                            className="custom-doc-nav-ba"
                            // href="#action/8.1"
                            style={{
                              color: "#fff",
                              fontSize: "14px",
                              fontFamily: "Rajdhani",
                            }}
                            onClick={()=>{
                              setShowApplyTemplate(true);
                              setApplyTemplateData(temp);
                            }}
                            >
                            {temp.name}
                          </NavDropdown.Item>
                        ))) : (
                          <NavDropdown.Item
                          className="custom-doc-nav-ba"
                          // href="#action/8.1"
                          style={{
                            color: "#fff",
                            fontSize: "14px",
                            fontFamily: "Rajdhani",
                          }}
                          >
                            No templates available
                          </NavDropdown.Item>
                        )
                      }
                      {myTemplatesCount > itemsPerPage && myTemplates.length < myTemplatesCount &&
                        <NavDropdown.Item
                        onClick={() => {
                          loadMoreClicked = true;
                        }}
                            className="custom-doc-nav-ba"
                            // href="#action/8.1"
                            style={{
                              color: "#fff",
                              fontSize: "14px",
                              fontFamily: "Rajdhani",
                            }}
                            onSelect={loadMoreTemplates}
                          >
                            Load More
                        </NavDropdown.Item>
                      }
                      </DropdownSubmenu>
                      <DropdownSubmenu
                        style={{
                          color: "#fff !important",
                          fontWeight: "bold",
                          fontSize: "14px",
                          fontFamily: "Rajdhani",
                        }}
                        title="Starter Templates"
                      >
                        { starterTemplates.length ? 
                        ( starterTemplates.map((temp, i) => (
                          <NavDropdown.Item
                            // href="#action/8.1"
                            style={{
                              color: "#fff",
                              fontSize: "14px",
                              fontFamily: "Rajdhani",
                            }}
                            onClick={()=>{
                              setShowApplyTemplate(true);
                              setApplyTemplateData(temp);
                            }}
                          >
                            {temp.name}
                          </NavDropdown.Item>
                        ))) : (
                          <NavDropdown.Item
                            className="custom-doc-nav-ba"
                            style={{
                              color: "#fff",
                              fontSize: "14px",
                              fontFamily: "Rajdhani",
                            }}
                          >
                            No starter templates available
                          </NavDropdown.Item>
                        )
                        }
                      </DropdownSubmenu>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="custom-width_2 ml-md-auto mr-md-2">
                <Nav.Link
                  href="/newTemplate"
                  className="title ml-xl-5 s_nav_item2 pl-2 pl-md-3 mobile-responsive "
                  target="_blank"
                >
                </Nav.Link>
                <p className=" mb-0 mr-5 s_nav_item2" onClick={saveAsTemplatePopUp}> Save As Template</p>
                {props.doc_id > 0 &&
                  <p className=" m-0 s_nav_item2" onClick={()=>{setShowDeleteModal(true)}}> Delete</p>
                }
                {typeof props.doc_id == 'undefined' &&
                  <p className=" m-0 s_nav_item2"> Delete</p>
                }
              </div>
            </Row>
          </div>
        </div>
      </Row>
    </ContentContainer>
  );
}
const LoadingContainer = styled.div`
    height:100vh;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    background: transparent;
    .loader{
        -webkit-user-select: none;
        margin: auto;
        // background-color: rgba(255,255,255, 0.5);
        // transition: background-color 300ms;
    }
`
const ContentContainer = styled.div`
  background: #f7f9fa;
  // position: fixed;
  top: 0;
  left: 0;
  // width:100%;

  z-index: 99;


  .slider {
      .show {
        height: 11.5em;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 0.5em;
        }
         
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }
         
        &::-webkit-scrollbar-thumb {
          background-color: darkgrey;
          outline: 1px solid slategrey;
        }
    }
  }
  .doc_lbl{
    display: flex;
  }
  .setting-input{
    height:40px;
    width:100%;
    outline:none;
    padding:10px;
    border-radius:4px;
    border:1px solid #B4BEBF;
    color:#212E33;
    font-style: normal;
    font-size:14px;
  }
  .dropdown-menu.show{
    @media(max-width:600px){
    left:-20px !important;
    top:0px !important;
    }
  }

  .wrap {
    position: relative;
    margin: auto;
    width: 100%;
    min-width: 320px;
    box-sizing: border-box;
    padding-left: 0px;
    padding-right: 0px;
  }

  .text-black {
    color: #333;
  }

  .temp_icon {
    font-size: 3rem;
    padding: 0.1rem 0.5rem;
    border-radius: 5px;

     padding-inline: 0 !important;
    }  @media(max-width: 767px) {
   
  }
  .s_nav_item {
    color: #212e33;
    font-size: 16px;
    font-family: Rajdhani;
    font-weight: 600 !important;
    margin-bottom: 0px !important;

    &:hover {
      color: #f7941c
    }

    @media (max-width: 767px) {
      margin-left: 5px !important;
    }
  }
  .s_nav_item:first-child {
    margin-left: 0px !important;
  }
  .s_nav_item_active {
    color: #f7941c;
  }
  .s_nav_item:hover{
    text-decoration:none;
  }

  .first_row {
    width: 100%;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 60px;
    @media (max-width: 767px) {
      width: 100%;
    }
    @media (max-width: 1024px) {
      padding: 0 20px;
      margin-top: 20px;
    }

    @media(max-width: 767px){
      padding: 0 30px;
    }
    .right-bar-link {
      display: flex;
      align-items: center;

      span {
        padding-right: 11px !important;
      }

      &:hover {
        color : #f7941c !important;
      }
    }
    .left-side {

      display: flex;
      align-items: center;
      // justify-content:space-between;
      @media (max-width: 767px) {
        padding: 3px;
      }

      img {
        width: 100px;
        height: auto;

        @media (max-width: 767px) {
          margin-left: 0 !important;
        } 
      }
      .title {
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
  .last-child {
    @media(max-width: 767px) {
      margin-left: 5px !important;
    }
  }

  .second_row {
    width: 100%;
    min-height: 45px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 100px;
    // background: #f5f5f5;
    @media (max-width: 1024px) {
      padding: 0 50px;
    }

    @media (max-width: 767px) {
      width: 100%;
      padding: 0 30px;
      flex-direction: column;

      .second-row-block {
        padding-inline : 0 !important;
      }
    }
    .custom-padding-dovNav {
      padding-top: 60px;


      /* @media(min-width:1400px){
                padding:60px 0px 0 0px;
            }
            @media(max-width:1024px){
                padding:60px 40px 0 40px;
            } */
            @media(max-width: 767px) {
              padding-top: 10px;
            }
    }


    .sec_row_info {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      /* @media(max-width:768px){
                padding:0 45px;
            } */
    }
    .right-side2 {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .right-side {
      display: flex;
      align-items: center;
      padding-right: 10px;
      // justify-content:space-around;

      @media (max-width: 767px) {
        width: 100%;
        justify-content: end;
        padding: 3px 0 3px 8px ;
      }
      .saveTempBtn {
        height: 44px;
        width: 211px;
        color: #212E33;
        border-radius: 3px;
        background-color: #4CADFF;
        font-family: Rajdhani;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.6px;
        text-transform:uppercase;
        line-height: 21px;
        text-align: center;
        display:flex;
        align-items:center;
        justify-content:center;

        &:hover {
          color: #212E33;
          background-color: #D6E1E5;
        }

        @media(max-width:600px){
          width:150px;
          height:33px;
          font-size:12px;
        }
      }
      .generate_estimates {
        height: 44px;
        width: 211px;
        color: #212E33;
        border-radius: 3px;
        background-color: #06d6a0;
        font-family: Rajdhani;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.6px;
        text-transform:uppercase;
        line-height: 21px;
        text-align: center;
        display:flex;
        align-items:center;
        justify-content:center;
        &:hover {
          color: #212e33;
          background-color: #d6e1e5;
        }

        @media(max-width:767px){
          width:150px;
          height:33px;
          font-size:12px;
        }
      }
    }
    .right-bar-link {
      margin-right: 1rem !important;
    }
    .left-side2 {
      width: 50%;
      display: flex;
      align-items: center;

      // justify-content:space-between;

      img {
        height: auto;
      }
      .title {
        font-size: 18px;
        font-weight: 500;
      }
      .title-ti {
        font-size: 28px;
        color: #212e33;
        font-family: Roboto;
      }
      @media(max-width: 768px) {
        .title-ti {
            font-size: 20px;
          }
      }
      @media (max-width: 600px) {
        width: 100%;
        justify-content: start;
        padding: 3px;
      }
      @media(max-width:400px){
        .title-ti {
            font-size: 14px;
          }
      }
    }
    .custom-width {
      width: 66%;
      min-height: 45px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // border-right:1px solid #c2c2c2;
      @media (max-width: 600px) {
        width: 100%;
      }

      .drop-down-btn {
        background: transparent !important;
        outline: none;
        border: none;
        box-shadow: none;

        &:hover {
          color : #f7941c !important;
        }
      }
      .left-side-icons {
        width: 70%;
        display: flex;
        align-items: center;
        // justify-content:space-evenly;

        @media (max-width: 1024px) {
          width: auto;
          .s_nav_item ,.s_nav_item2{
            font-size: 13px !important;
          }
        }
        @media (max-width: 767px) {
          width: 100% !important;
          display: flex;
          justify-content: space-between;
          padding-inline: 0;
          padding-bottom: 10px;
        }
      }
      .uper-left {
        margin-right: 5px;
        .title {
          margin: 0 5px;
          font-weight: 500;
        }
      }
    }
    .custom-width_2 {
      min-height: 45px;
      width: 32%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-left: 0px;
      @media (max-width: 1413px) {
        margin-left: 5px;
      }

      @media (max-width: 767px) {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-inline: 0;
        margin-left: 0px;
        margin-top: -10px;

        .mobile-responsive {
          display: none;
        }
      }

      .s_nav_item2 {
        color: #212e33 !important;
        font-size: 16px;
        font-weight: 600;
        font-family: Rajdhani !important;
        cursor:pointer;
        &:hover {
          color : #f7941c !important;
        }
      }
    }
  }
`;

const Btn = styled.div`
  min-width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 20px;
  background: red;
  padding: 20px;
  font-size: 17px;
  font-weight: 500;
  text-decoration: none;
`;
